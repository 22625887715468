.home-about-description {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  margin-bottom: 20px;
}

.home-about-description h1 {
  width: 100%;
  text-align: center;
}

.home-about-description ul {
  width: 100%;
}

.text-top {
  margin-top: 150px;
}

@media (max-width: 768px) {
  .text-top {
    margin-top: 40px;
  }
}
@import url("https://fonts.googleapis.com/css?family=Lato:300");
.shimmer {
    font-family: "Lato";
    font-weight: 600;
    font-size: 3em;
    margin: 0 auto;
    padding: 0 140px 0 0;
    display: inline;
    margin-bottom: 0;
}

.shimmer {
    text-align: center;
    color: rgba(255, 255, 255, 0.1);
    background: -webkit-gradient(linear, left top, right top, from(#c686e4), to(#c686e4), color-stop(0.5, #fff));
    background: -moz-gradient(linear, left top, right top, from(#c686e4), to(#c686e4), color-stop(0.5, #fff));
    background: gradient(linear, left top, right top, from(#c686e4), to(#c686e4), color-stop(0.5, #fff));
    -webkit-background-size: 125px 100%;
    -moz-background-size: 125px 100%;
    background-size: 125px 100%;
    -webkit-background-clip: text;
    -moz-background-clip: text;
    background-clip: text;
    -webkit-animation-name: shimmer;
    -moz-animation-name: shimmer;
    animation-name: shimmer;
    -webkit-animation-duration: 2s;
    -moz-animation-duration: 2s;
    animation-duration: 2s;
    -webkit-animation-iteration-count: infinite;
    -moz-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    background-repeat: no-repeat;
    background-position: 0 0;
    background-color: #c686e4;
}

@-moz-keyframes shimmer {
    0% {
        background-position: top left;
    }
    100% {
        background-position: top right;
    }
}

@-webkit-keyframes shimmer {
    0% {
        background-position: top left;
    }
    100% {
        background-position: top right;
    }
}

@-o-keyframes shimmer {
    0% {
        background-position: top left;
    }
    100% {
        background-position: top right;
    }
}

@keyframes shimmer {
    0% {
        background-position: top left;
    }
    100% {
        background-position: top right;
    }
}
